export const Color = {
  blackAlpha50: 'rgba(0, 0, 0, 0.04)',
  blackAlpha100: 'rgba(0, 0, 0, 0.06)',
  blackAlpha200: 'rgba(0, 0, 0, 0.08)',
  blackAlpha300: 'rgba(0, 0, 0, 0.16)',
  blackAlpha400: 'rgba(0, 0, 0, 0.24)',
  blackAlpha500: 'rgba(0, 0, 0, 0.36)',
  blackAlpha600: 'rgba(0, 0, 0, 0.48)',
  blackAlpha700: 'rgba(0, 0, 0, 0.64)',
  blackAlpha800: 'rgba(0, 0, 0, 0.80)',
  blackAlpha900: 'rgba(0, 0, 0, 0.92)',
  blue: '#108BFF',
  red: '#FF4747'
}
