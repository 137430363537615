import { Alert, Snackbar } from '@mui/material'
import React, { FC, ReactNode, useCallback } from 'react'
import { atom, useRecoilState, useSetRecoilState } from 'recoil'

type Variant = 'error' | 'success'

const DEFAULT_DURATION = 3000
type SnackbarParams = {
  id: number
  open: boolean
  variant: Variant
  message: string
  /** in milliseconds(default: 3000) */
  duration?: number
}

type State = {
  current?: SnackbarParams
  // queue: SnackbarParams[]
}

const snackbarState = atom<State>({
  key: 'ui.snackbar',
  default: {}
})

let snackbarId = 0

export const useSnackbar = () => {
  const setState = useSetRecoilState(snackbarState)

  const showSnackbar = useCallback(
    (variant: Variant, message: string, options?: Pick<SnackbarParams, 'duration'>) => {
      const params: SnackbarParams = {
        id: ++snackbarId,
        open: true,
        variant,
        message,
        duration: options?.duration
      }

      setState({ current: params })
    },
    [setState]
  )

  return { showSnackbar }
}

const transitionDuration = 200

export const SnackbarProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [{ current }, setState] = useRecoilState(snackbarState)

  const onClose = useCallback(() => {
    setState(prev => {
      return { current: { ...prev.current!, open: false } }
    })
  }, [setState])

  return (
    <>
      <Snackbar
        key={current?.id}
        open={current?.open}
        autoHideDuration={current?.duration ?? DEFAULT_DURATION}
        onClose={onClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transitionDuration={transitionDuration}
      >
        <Alert
          key={current?.id}
          severity={current?.variant}
          variant="filled"
          sx={{
            width: '100%',
            fontSize: 16,
            fontWeight: 500,
            boxShadow:
              '0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px rgba(0, 0, 0, 0.14), 0px 1px 18px rgba(0, 0, 0, 0.12)'
          }}
        >
          {current?.message}
        </Alert>
      </Snackbar>
      {children}
    </>
  )
}
