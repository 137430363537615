import React, { ErrorInfo, ReactNode } from 'react'

const hasSentryAuthToken = !!process.env.NEXT_PUBLIC_SENTRY_DSN
class ErrorBoundary extends React.Component<{ children?: ReactNode | undefined }, { hasError: boolean }> {
  constructor(props: {}) {
    super(props)
    this.state = {
      hasError: false
    }
  }
  static getDerivedStateFromError(): { hasError: boolean } {
    return { hasError: true }
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
    if (!hasSentryAuthToken) {
      console.warn('dosent have SentryAuthToken')
    }
    console.error(JSON.stringify({ error, errorInfo }))
  }

  render() {
    return <>{this.props.children}</>
  }
}

export default ErrorBoundary
