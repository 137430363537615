import { createTheme, CssBaseline, GlobalStyles, ThemeProvider } from '@mui/material'
import { Colors } from '@singcolor/components'
import { AppProps } from 'next/app'
import React, { FC, ReactNode } from 'react'
import { RecoilRoot } from 'recoil'
import { SnackbarProvider } from 'src/modules/snackbar/hooks'
import { Color } from 'src/styles/color'
// import { SnackbarProvider } from 'src/modules/snackbar/hooks'
import { SWRConfig } from 'swr'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import ja from 'date-fns/locale/ja'
import Head from 'next/head'
import ErrorBoundary from 'src/components/atoms/ErrorBoundary'
import { NextSeo } from 'next-seo'

const Providers: FC<{ children: ReactNode }> = ({ children }) => {
  return (
    <SWRConfig
      value={{
        revalidateOnFocus: false,
        revalidateOnReconnect: false,
        shouldRetryOnError: false
      }}
    >
      <RecoilRoot>
        <ErrorBoundary>
          <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ja}>
            <ThemeProvider theme={createTheme({ palette: { ...Colors, divider: Color.blackAlpha200 } })}>
              <SnackbarProvider>{children}</SnackbarProvider>
            </ThemeProvider>
          </LocalizationProvider>
        </ErrorBoundary>
      </RecoilRoot>
    </SWRConfig>
  )
}

const title = 'ColorSing (カラーシング) -歌特化ライブ配信アプリ-'
const description =
  'JOYSOUND提携で10万曲収録の歌特化ライブ配信。審査に通過した歌うまライバーたちがあなたのために歌ってくれます。お気に入りの歌声をみつけて好きな歌をリクエストしてみよう！'
const url = 'https://web.colorsing.com'

const App = ({ Component, pageProps }: AppProps) => {
  return (
    <>
      <Head>
        <meta name="viewport" content="width=device-width,initial-scale=1.0,maximum-scale=1.0" />
      </Head>
      <NextSeo
        title={title}
        description={description}
        openGraph={{
          type: 'website',
          url,
          title,
          description,
          images: [
            {
              url: `${url}/ogp.png`,
              width: 1200,
              height: 630
            }
          ]
        }}
        twitter={{
          cardType: 'summary',
          site: '@colorsing_jp'
        }}
        additionalLinkTags={[
          {
            rel: 'icon',
            href: `/favicon.ico`
          },
          {
            rel: 'apple-touch-icon',
            href: `/apple-touch-icon.png`,
            sizes: '150x150'
          }
        ]}
      />
      <Providers>
        <CssBaseline />
        <GlobalStyles styles={{ html: { backgroundColor: Color.blackAlpha50 } }} />
        <Component {...pageProps} />
      </Providers>
    </>
  )
}

export default App
